import { reactNode } from "@packages/node";
import { getPort } from "@packages/port";

import v100 from "@packages/pdf-link-v1.0.0";

export default reactNode(
  "PdfLink",
  {
    "v1.0.0": {
      module: { static: v100 },
      inputs: [
        getPort({
          plug: "input",
          name: "src",
          displayName: "Source URL",
          group: "Params",
          type: "string",
          customs: { required: "connection" },
        }),
        getPort({
          plug: "input",
          name: "style",
          displayName: "Style",
          group: "Params",
          type: "object",
        }),
        getPort({
          plug: "input",
          name: "customProps",
          displayName: "Custom Props",
          group: "Params",
          type: "object",
        }),
      ],
    },
  },
  { allowChildren: true }
);
