import { Link } from "@react-pdf/renderer";
import { forwardRef } from "react";
import type { Props } from "./types";
import React from "react";
import { getCompProps } from "@packages/get-comp-props";

export default forwardRef(function (props: Props) {
    const p = { ...getCompProps(props) } as Props;

    return (
        <Link
            src={p.src}
            style={p.style}
            {...p.customProps}
        >
            {p.children}
        </Link>
    );
});
